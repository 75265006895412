@font-face {
  font-family: 'TTCommons';
  src:  
        url('./fonts/tt-commons-cufonfonts/TT Commons Regular.otf') format('opentype'),
        url('./fonts/tt-commons-cufonfonts/TT Commons Bold.otf') format('opentype'),
        url('./fonts/tt-commons-cufonfonts/TT Commons Black.otf') format('opentype'),
        url('./fonts/tt-commons-cufonfonts/TT Commons DemiBold.otf') format('opentype'),
        url('./fonts/tt-commons-cufonfonts/TT Commons Thin.otf') format('opentype'),
        url('./fonts/tt-commons-cufonfonts/TT Commons Medium.otf') format('opentype'),
        /* ,
       url('../fonts/your-font.woff') format('woff') */
       ;
  /* font-weight: normal;
  font-style: normal; */
}


/* Or use a universal selector */
* {
  font-family: 'TTCommons', 'Open Sans'   !important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sent-row{
  background-color: rgba(10, 174, 45, 0.048);
}
.failed-row{
  background-color: rgba(174, 13, 10, 0.066);
}
.sending-row{
  background-color: rgba(167, 193, 241, 0.115);
}
.nn{
  background-color: #61dafb;
}

.ActionIcon{
  cursor: pointer;
  margin: 2px 5px;
}

.clicked_link{
  background-color: #a10419;
}


/* Style the draggable elements */
.draggable {
  display: inline-block;
  padding: 10px;
  margin: 10px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  cursor: move;
}

/* Style the drop zone */
.drop-zone {
  padding: 20px;
  margin: 20px;
  border: 2px dashed #ccc;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

/* Style the dropped elements */
.dropped {
  display: inline-block;
  padding: 10px;
  margin: 10px;
  background-color: #ffc107;
  border: 1px solid #ff9800;
}

.draggedElement{
  transition: 200ms;
  cursor: pointer;
  margin: 5px 0%;
}
.draggedElement:hover {
  position: relative;
  box-shadow: rgb(116, 210, 248) 0px 0px 0px 1px ;
}

.draggedElement:hover .deleteIcon:not(:hover){
  display: block !important;
}

.draggedElement .MyDeleteIcon{
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: none;
  flex-direction: row;
  justify-content: center;
}

.draggedElement ~ .MyDeleteIcon{
  display: none;
  flex-direction: row;
  justify-content: center;
}

.draggedElement:hover .MyDeleteIcon{
  display: flex !important;
}

.draggedElement:hover ~ .draggedElement:first-child .MyDeleteIcon{
  display: flex !important;
}


.headerBtn{
  width: max-content;
  color: #5349ce !important;
  margin: 2px 5px;
  padding: 5px;
  cursor: pointer;
}
.hr1{
  width: max-content;
  color: white;
  margin: 2px 0px;
  cursor: pointer;
}
.newItemAdding{
  transition: 400ms;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.newItemAdding:hover{
  height: 100% !important;
  border: 1px solid #61dafb !important;
  padding: 10px !important;
}

.newItemAdding:hover .myAddIcon{
  display: block !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiDataGrid-root .MuiDataGrid-cell--header .MuiDataGrid-colCell:first-child .MuiDataGrid-colCellTitle {
  font-weight: 600;
}

.hovering{
  cursor: pointer;
}

.rowToColumn {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
}


@media only screen and (max-width: 820px) {
  .rowToColumn {
    flex-direction: column !important;
    align-items: center;
  }
  .toFullWidth{
    width: 90% !important;
  }
  .toFullWidth100{
    width: 100% !important;
  }
}

.clientDetails .MuiBox-root.css-19kzrtu {
  padding: 0px;
}

.sent-row1{
  background-color: rgba(114, 154, 114, 0.052);
}

.failed-row1{
  background-color: rgba(175, 22, 22, 0.118);
}